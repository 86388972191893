import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormSelector from "./FormSelector";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { getAllAcquiredVoucher } from "../../../actions/acquiredVoucher";

const CustomAcquiredVoucherSelector = ({
	customKey,
	register,
	config,
	errors,
}) => {
	const dispatch = useDispatch();
	const clinicId = useSelector((state) => state.activeClinic.id);
	const [vouchers, setVouchers] = useState({ content: {}, order: [], waiting: true });

	useEffect(() => {
		if (!isDefined(config.defaultValue)) {
			config.setValue(config.name, "select")
		}

		fetchData(
			dispatch,
			getAllAcquiredVoucher,
			{
				clinic_id: clinicId,
				patient_id: config.patientId,
				order: 'remaining_session-due_date',
				per_page: 1000,
				page: 1,
				remaining_sessions: true,
				acquired_voucher_id: config.defaultValue,
			},
			setVouchers
		);
	}, []);

	const checkVoucherSelected = (value) => {
		if (config.name.includes('tmp')) return;

		if (!isDefined(value) || value === "select") {
			return "El bono es obligatorio";
		}
	};

	const options = [
		...(config.defaultValue ? [] :
			[{ key: "select", label: "Selecciona el bono adquirido" }]
		),
		...(vouchers?.order
			? vouchers?.order?.map((id) => {
				const v = vouchers.content[id];

				// check if it is the default value
				if (v?.acquired_voucher_id?.toString() === config?.defaultValue?.toString()) {
					return {
						key: id.toString(),
						label: v.display_name,
					};
				}

				// check if due date is between start and end date
				if (isDefined(config.entityDate)) {
					const entityDate = (new Date(config.entityDate)).getTime();
					const startDate = (new Date(v.start_date)).getTime();
					const dueDate = (new Date(v.due_date)).getTime();

					// Not between start and end date
					if (startDate > entityDate || dueDate < entityDate) return;
				}

				// check if display all is true
				if (config.displayAll) {
					return {
						key: id.toString(),
						label: v.display_name,
					};
				}

				// check if session type or group session type is the same
				if (
					(isDefined(v?.session_type_id) && v?.session_type_id?.toString() === config?.sessionTypeId?.toString()) ||
					(isDefined(v?.group_session_type_id) && v?.group_session_type_id?.toString() === config?.groupSessionTypeId?.toString())
				) {
					return {
						key: id.toString(),
						label: v.display_name,
					};
				}

				// Not valid
				return;
			}).filter(v => v !== undefined)
			: []),
	];

	if (vouchers.waiting)
		return (
			<FormSelector
				customKey={customKey + '-tmp'}
				register={register}
				config={{
					condition: config.condition,
					disabled: config.disabled,
					type: "selector",
					name: config.name + '-tmp',
					classname: config.classname,
					label: config.label,
					info: config.info,
					options: [{ key: config.defaultValue || options[0]?.key, label: "Cargando bonos adquiridos..." }],
					defaultValue: "loading",
				}}
				errors={undefined}
			/>
		);

	return (
		<FormSelector
			customKey={customKey}
			register={register}
			config={{
				condition: config.condition,
				disabled: config.disabled,
				type: "selector",
				classname: config.classname,
				name: config.name,
				label: config.label,
				info: config.info,
				options: options,
				defaultValue: isDefined(config.defaultValue) ? config.defaultValue.toString() : "select",
				validators: { validate: checkVoucherSelected },
			}}
			errors={errors}
		/>
	);
};

export default CustomAcquiredVoucherSelector;
