import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import OverviewSessionsInAcquiredVoucher from "../../../components/services/voucher/acquiredVoucher/OverviewSessionsInAcquiredVoucher";
import CustomPatientsHandler from "../../../components/general/form/CustomPatientsHandler";
import CustomForm from "../../../components/general/form/CustomForm";
import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import {
	getAcquiredVoucher,
	putAcquiredVoucher,
	deleteAcquiredVoucher,
} from "../../../actions/acquiredVoucher";
import {
	deletePatientAuthorizedAcquiredVoucher,
	postPatientAuthorizedAcquiredVoucher
} from "../../../actions/patientAuthorizedAcquiredVoucher";
import { isDefined } from "../../../auxiliar/formatValidators";

const AcquiredVoucherCard = () => {
	const modalId = "delete-acquired-voucher-modal";
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		fetchFormValues,
		resetFetchedValues,
		openModal,
		watch,
		setValue,
		errors,
		isSubmitting,
	} = useFormWithDisable(modalId);

	const navigate = useNavigate();

	const [isEditing, setIsEditing] = useState(false);
	const [operation, setOperation] = useState({
		patient_id: undefined,
		operation: "displayAssisting",
		update: false,
	});
	const [deletingOperation, setDeletingOperation] = useState({
		operation: "deleteSession",
		metadata: {},
	});

	const dueDate = watch("due_date");
	const startDate = watch("start_date")
	const deletable = watch("deletable");
	const type = watch("group_indiv");
	const groupSessionTypeId = watch("group_session_type_id");
	const sessionTypeId = watch("session_type_id");
	const periodUnit = watch("voucher_type_validity_period_unit")
	const cashRegisterID = watch("cash_register_id")
	const remainingSessions = isDefined(watch("remaining_sessions")) ? watch("remaining_sessions") : "Ilimitadas"
	const totalSessions = isDefined(watch("voucher_type_total_sessions"))
		? watch("voucher_type_total_sessions")
		: "Ilimitadas"

	const { acquired_voucher_id: acquiredVoucherId } = useParams();
	const queryParams = useQueryParams();
	const activeClinicId = useSelector((state) => state.activeClinic.id);

	const openModalAux = (entity, metadata) => {
		setDeletingOperation({
			operation: entity,
			metadata: metadata || {},
		});
		openModal();
	}

	const refresh = () => {
		setValue("patient_id", undefined);
		setOperation({
			patient_id: undefined,
			operation: "displayAssisting",
			update: !operation.update,
		});
	};

	const formConfig = {
		group_indiv: {
			condition: true,
			disabled: true,
			type: "selector",
			name: "group_indiv",
			label: "Tipo de bono",
			options: [
				{ key: "individual", label: "Sesiones individuales" },
				{ key: "group", label: "Clases" },
			],
			defaultValue: "individual",
		},
		session_type_id: {
			condition: type === "individual",
			disabled: true,
			type: "session_type_selector",
			name: "session_type_id",
			label: "Tipo de sesión",
			defaultValue: sessionTypeId,
		},
		group_session_type_id: {
			condition: type === "group",
			disabled: true,
			type: "group_session_type_selector",
			name: "group_session_type_id",
			label: "Tipo de clase",
			defaultValue: groupSessionTypeId,
		},
		voucher_type_display_name: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Nombre",
			placeholder: "Nombre",
			name: "voucher_type_display_name",
		},
		voucher_type_initial_price: {
			condition: true,
			disabled: true,
			type: "number",
			label: "Precio",
			placeholder: "Ejemplo: 100",
			name: "voucher_type_initial_price",
			info: "Precio del bono en euros",
			minValue: 0,
		},
		voucher_type_total_sessions: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Número de sesiones",
			placeholder: "10",
			name: "voucher_type_total_sessions",
			defaultValue: totalSessions,
			minValue: 1,
		},
		voucher_type_validity_periods: {
			condition: true,
			disabled: true,
			type: "number",
			label: `${periodUnit === "m" ? "Meses" : "Semanas"} de validez por defecto`,
			placeholder: "Ejemplo: 12",
			name: "voucher_type_validity_periods",
		},
		start_date: {
			condition: true,
			disabled: !isEditing,
			type: "date",
			label: "Fecha de inicio *",
			name: "start_date",
			validators: {
				required: "La fecha de inicio es obligatoria"
			},
		},
		due_date: {
			condition: true,
			disabled: !isEditing,
			type: "date",
			label: "Fecha de caducidad *",
			name: "due_date",
			validators: {
				required: "La fecha de caducidad es obligatoria",
				validate: (v) =>
					new Date(v) > new Date(startDate) ||
					"La fecha de caducidad debe ser posterior a la de inicio",
			},
		},
		remaining_sessions: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Sesiones restantes",
			name: "remaining_sessions",
			defaultValue: remainingSessions,
		},
		dummy: {
			condition: true,
			disabled: false,
			type: "dummy",
			name: "dummy",
		},
		auto_assign: {
			condition: true,
			disabled: !isEditing,
			type: "checkbox",
			name: "auto_assign",
			label: "Asignado automáticamente",
		},
	};

	const modalOptions = {
		deleteSession: {
			title: "¿Estás seguro de que quieres eliminar todos los datos del bono adquirido?",
			text: "No podremos recuperar la información más adelante.",
			onConfirm: handleSubmitWithoutValidation(
				deleteAcquiredVoucher,
				acquiredVoucherId,
				queryParams.get("patient")
			),
		},
		deletePatient: {
			title: "¿Estás seguro de que quieres eliminar este paciente del bono adquirido?",
			text: "Las sesiones pagadas con este bono que pertenezcan a este paciente no se verán afectadas, pero el bono no podrá asociarse a más sesiones de este paciente.",
			onConfirm: handleSubmitWithoutValidation(
				deletePatientAuthorizedAcquiredVoucher,
				deletingOperation.metadata.patient_id,
				acquiredVoucherId,
				modalId,
				refresh
			),
		},
	};

	useEffect(() => {
		if (acquiredVoucherId)
			fetchFormValues(getAcquiredVoucher, acquiredVoucherId);
	}, [acquiredVoucherId]);

	useEffect(() => {
		if (!isEditing && acquiredVoucherId) resetFetchedValues();
	}, [isEditing]);

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<div className="flex items-center justify-between">
				<h2 className="text-primary text-2xl">Datos del bono adquirido</h2>

				{cashRegisterID && (
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmitWithoutValidation(navigate, `/cash-register/${cashRegisterID}`)}
						disabled={isSubmitting}
					>
						Ver albarán
					</button>
				)}
			</div>


			<div className="mt-3">
				<form>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
						<CustomForm
							formConfig={formConfig}
							errors={errors}
							register={register}
						/>
					</div>

					<div className="mt-5 flex space-x-2 sm:space-x-4">
						{!isEditing ? (
							<>
								<button
									className="btn btn-primary btn-sm"
									onClick={handleSubmitWithoutValidation(setIsEditing, true)}
									disabled={isSubmitting}
								>
									Editar
								</button>
								{deletable && (
									<button
										className="btn btn-error btn-sm"
										onClick={handleSubmitWithoutValidation(openModalAux, "deleteSession")}
										disabled={isSubmitting}
									>
										Eliminar
									</button>
								)}
								<button
									className="btn btn-primary btn-sm"
									onClick={handleSubmitWithoutValidation(
										navigate,
										queryParams.has("from_cash_register_deletion") && queryParams.get("from_cash_register_deletion") === 'true' ? -2 : -1)
									}
									disabled={isSubmitting}
								>
									Atrás
								</button>
							</>
						) : (
							<>
								<button
									className="btn btn-primary btn-sm"
									onClick={handleSubmit(
										putAcquiredVoucher,
										acquiredVoucherId,
										handleSubmitWithoutValidation(setIsEditing, false)
									)}
									disabled={isSubmitting}
								>
									Guardar
								</button>
								<button
									className="btn btn-primary btn-sm"
									onClick={handleSubmitWithoutValidation(setIsEditing, false)}
									disabled={isSubmitting}
								>
									Cancelar
								</button>
							</>
						)}
					</div>
				</form>
			</div>

			<div className="my-8">
				<CustomPatientsHandler
					register={register}
					errors={errors}
					watch={watch}
					setValue={setValue}
					filter={{
						clinic_id: activeClinicId,
						exclude_acquired_voucher_id: acquiredVoucherId,
						per_page: 5,
						order: "name",
					}}
					filterAdded={{
						clinic_id: activeClinicId,
						acquired_voucher_id: acquiredVoucherId,
					}}
					disabled={isSubmitting || new Date().getTime() > new Date(dueDate).getTime()}
					operation={operation}
					onExistingClick={handleSubmitWithoutValidation(setOperation, { ...operation, patient_id: undefined, operation: "displayAssisting" })}
					onAddExistingClick={handleSubmitWithoutValidation(setOperation, { ...operation, patient_id: undefined, operation: "addPatient" })}
					onAddNewClick={handleSubmitWithoutValidation(setOperation, { ...operation, patient_id: "new", operation: "addPatient" })}
					onClientSelect={(patient) => handleSubmitWithoutValidation(postPatientAuthorizedAcquiredVoucher({}, patient.patient_id, activeClinicId, acquiredVoucherId, refresh))()}
					onClientRegister={() => handleSubmit(postPatientAuthorizedAcquiredVoucher, "new", activeClinicId, acquiredVoucherId, refresh)()}
					onDeletePatient={(patient) => handleSubmitWithoutValidation(openModalAux, "deletePatient", { patient_id: patient.patient_id })()}
				/>
			</div>

			<OverviewSessionsInAcquiredVoucher
				acquiredVoucherId={acquiredVoucherId}
			/>

			<ModalConfirm
				id={modalId}
				title={modalOptions[deletingOperation.operation].title}
				text={modalOptions[deletingOperation.operation].text}
				onConfirm={modalOptions[deletingOperation.operation].onConfirm}
			/>
		</div>
	);
};
export default AcquiredVoucherCard;
