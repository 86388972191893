import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PayWithCashRegister from "../../../components/payment/PayWithCashRegister";
import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import { postAcquiredVoucher } from "../../../actions/acquiredVoucher";
import { getPatientDiscount } from "../../../actions/patient";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { sessionsNumberValidators } from "../../../auxiliar/useFormValidators";


const RegisterAcquiredVoucher = () => {
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		watch,
		setValue,
		errors,
		isSubmitting,
	} = useFormWithDisable();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const queryParams = useQueryParams();
	const activeClinicId = useSelector((state) => state.activeClinic.id);

	const voucherData = watch("voucher_data")
	const voucherType = watch("voucher_type_id")
	const startDate = watch("start_date")
	const defaultRemainingSessions = voucherData?.split("-")?.[1]

	const formConfig = {
		voucher_data: {
			condition: true,
			disabled: false,
			type: "voucher_selector_with_metadata",
			label: "Tipo de bono *",
			name: "voucher_data",
		},
		employee_id: {
			condition: true,
			disabled: false,
			type: "physio_selector",
			label: "Fisioterapeuta *",
			name: "employee_id",
		},
		start_date: {
			condition: true,
			disabled: false,
			type: "date",
			label: "Fecha de inicio *",
			name: "start_date",
			defaultValue: (new Date()).toISOString().slice(0, 10),
			validators: {
				required: "La fecha de inicio es obligatoria",
			}
		},
		due_date: {
			condition: true,
			disabled: false,
			type: "date",
			label: "Fecha de caducidad *",
			name: "due_date",
			info: "El día de caducidad del bono es el último dia de validez",
			validators: {
				required: "La fecha de caducidad es obligatoria",
				validate: (v) =>
					new Date(v) > new Date(startDate) ||
					"La fecha de caducidad debe ser posterior a la de inicio",
			}
		},
		remaining_sessions: {
			condition: true,
			disabled: false,
			type: "number",
			label: `Sesiones restantes${isDefined(defaultRemainingSessions) ? " *" : ""}`,
			placeholder: "Ejemplo: 10",
			name: "remaining_sessions",
			validators: isDefined(defaultRemainingSessions) ? sessionsNumberValidators : undefined,
		},
		dummy: {
			condition: true,
			disabled: false,
			type: "dummy",
			name: "dummy",
		},
		auto_assign: {
			condition: true,
			disabled: false,
			type: "checkbox",
			name: "auto_assign",
			label: "Asignado automáticamente",
			info: "Si se marca, el bono se asignará automáticamente a las sesiones del tipo de sesión aceptado por el bono en el momento de la compra",
		}
	};

	// sets the default discount for the user
	useEffect(() => {
		fetchData(
			dispatch,
			getPatientDiscount,
			queryParams.get("patient"),
			(r) => setValue("discount_percentage", r?.discount_percentage || 0)
		)
	}, []);

	useEffect(() => {
		if (isDefined(voucherData) && voucherData?.split("-").length === 5) {
			// payment data is handled in the payment component, but initial values are set here
			const [voucherId, remainingSessions, periodType, periods, price] = voucherData?.split("-");

			const dueDate = new Date(startDate);
			if (periodType === "m") {
				// First add the full months, then subtract one day
				dueDate.setMonth(dueDate.getMonth() + parseInt(periods))
				dueDate.setDate(dueDate.getDate() - 1)
			} else if (periodType === "w") {
				// Subtract 1 since we're including the current day
				dueDate.setDate(dueDate.getDate() + (parseInt(periods) * 7 - 1))
			}

			const discountPercentage = watch("discount_percentage")
			const initialPrice = Number(price).toFixed(2);
			const finalPrice = (Number(price) * (100 - discountPercentage) / 100).toFixed(2)

			// Voucher fields
			setValue("voucher_type_id", voucherId);
			setValue("remaining_sessions", isDefined(remainingSessions) ? remainingSessions : undefined);
			setValue("due_date", dueDate.toISOString().slice(0, 10));

			// Payment fields, the form needs to load before setting the values
			setTimeout(() => {
				setValue("initial_price", initialPrice);
				setValue("final_price", finalPrice);
				setValue("total_paid", "0");
				setValue("debt", finalPrice);
				setValue("paid", "0");
				setValue("discount_percentage", discountPercentage);
				setValue("discount", (initialPrice - finalPrice).toFixed(2));
			}, 10);
		} else {
			// Voucher fields
			setValue("voucher_type_id", undefined);
			setValue("remaining_sessions", 0);
			setValue("due_date", undefined);
		}
	}, [voucherData]);

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Adquirir bono</h2>
			<div className="mt-5 mb-12">
				<form className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</form>

				{isDefined(voucherType) && (
					<div className="mt-5">
						<h4 className="text-primary text-lg">Datos de Pago</h4>

						<PayWithCashRegister
							register={register}
							errors={errors}
							setValue={setValue}
							watch={watch}
						/>
					</div>
				)}

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(
							postAcquiredVoucher,
							activeClinicId,
							queryParams.get("patient")
						)}
						disabled={isSubmitting}
					>
						Confirmar
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmitWithoutValidation(
							navigate,
							`/patient/${queryParams.get("patient")}`
						)}
						disabled={isSubmitting}
					>
						Cancelar
					</button>
				</div>
			</div>
		</div>
	);
};

export default RegisterAcquiredVoucher;
