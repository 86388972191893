import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import OverviewDocuments from "../patient/document/OverviewDocuments";
import CustomForm from "../general/form/CustomForm";
import ModalConfirm from "../general/auxiliar/modal/ModalConfirm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { deletePatient, getPatient, putPatient } from "../../actions/patient";
import {
	checkMailFormat,
	checkPhoneNumberFormat,
	isDefined,
	checkDNIFormat,
} from "../../auxiliar/formatValidators";
import { birthDateValidators, discountValidators } from "../../auxiliar/useFormValidators";

const EditPatient = ({ refreshBool }) => {
	const modalId = "delete-patient-modal";
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		fetchFormValues,
		resetFetchedValues,
		openModal,
		watch,
		errors,
		isSubmitting,
	} = useFormWithDisable(modalId);

	const { patient_id: patientId } = useParams();
	const navigate = useNavigate();
	const [isEditing, setIsEditing] = useState(false);
	const comment = watch("comments");
	const debt = watch("debt");

	const formConfig = {
		name: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Nombre *",
			placeholder: "Nombre",
			name: "name",
			validators: { required: "El nombre es obligatorio" },
		},
		surnames: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Apellidos *",
			placeholder: "Apellidos",
			name: "surnames",
			validators: { required: "Los apellidos son obligatorios" },
		},
		email: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Correo Electrónico",
			placeholder: "paciente@email.com",
			name: "email",
			validators: { validate: (v) => !isDefined(v) || checkMailFormat(v) },
		},
		phone_number: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Número de teléfono",
			placeholder: "+34 XXX XXX XXX",
			name: "phone_number",
			validators: {
				validate: (v) => !isDefined(v) || checkPhoneNumberFormat(v),
			},
		},
		dni: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "DNI",
			name: "dni",
			placeholder: "12345678A",
			validators: { validate: (v) => !isDefined(v) || checkDNIFormat(v) },
		},
		address: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Direccion",
			placeholder: "Calle 123, Ciudad",
			name: "address",
		},
		birth_date: {
			condition: true,
			disabled: !isEditing,
			type: "date",
			label: "Fecha de nacimiento",
			name: "birth_date",
			validators: birthDateValidators
		},
		discount_percentage: {
			condition: true,
			disabled: !isEditing,
			type: "number",
			label: "Porcentaje de descuento habitual",
			placeholder: "Ejemplo: 10",
			name: "discount_percentage",
			info: "Podrás modificar el descuento en cada cita. Si no se introduce un valor, se guardará como 0.",
			validators: discountValidators,
			minValue: 0,
			maxValue: 100
		},
		comments: {
			condition: true,
			disabled: !isEditing,
			type: "textarea",
			label: `Anamnesis (${comment?.length || 0}/10000)`,
			placeholder: "...",
			name: "comments",
			validators: {
				maxLength: {
					value: 10000,
					message: "El comentario es demasiado largo",
				},
			},
		},
		reminders: {
			condition: true,
			disabled: !isEditing,
			info: "Si activas los recordatorios podrás enviarle mensajes para recordarle las citas desde la aplicación.",
			label: "Activar recordatorios",
			type: "checkbox",
			name: "reminders",
		},
	};

	useEffect(() => {
		if (patientId) fetchFormValues(getPatient, patientId);
	}, [patientId, refreshBool]);

	useEffect(() => {
		if (!isEditing && patientId) resetFetchedValues();
	}, [isEditing]);

	return (
		<>
			<h2 className="text-primary text-2xl">Paciente</h2>

			<div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="my-2 grid grid-cols-1 md:grid-cols-6 gap-5">
					<div className="md:col-span-4 xl:col-span-5">
						<OverviewDocuments />
					</div>

					<div className="stats shadow bg-base-200 text-center align-middle md:col-span-2 xl:col-span-1">
						<div className="stat max-w-full">
							<div className="stat-title text-primary text-xl break-words whitespace-normal">
								Deuda
							</div>
							<div className="stat-value break-words whitespace-normal">{isDefined(debt) && Number(debt).toFixed(2)}€</div>
							<div className="stat-desc break-words whitespace-normal">
								Incluyendo sesiones, clases y bonos
							</div>
						</div>
					</div>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					{!isEditing ? (
						<>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(setIsEditing, true)}
								disabled={isSubmitting}
							>
								Editar
							</button>
							<button
								className="btn btn-error btn-sm"
								onClick={openModal}
								disabled={isSubmitting}
							>
								Eliminar
							</button>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(navigate, -1)}
								disabled={isSubmitting}
							>
								Atrás
							</button>
						</>
					) : (
						<>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmit(
									putPatient,
									patientId,
									handleSubmitWithoutValidation(setIsEditing, false)
								)}
								disabled={isSubmitting}
							>
								Guardar
							</button>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(setIsEditing, false)}
								disabled={isSubmitting}
							>
								Cancelar
							</button>
						</>
					)}
				</div>
			</div>

			<ModalConfirm
				id={modalId}
				title="¿Estás seguro de que quieres eliminar todos los datos del paciente?"
				text="No podremos recuperar la información más adelante."
				onConfirm={handleSubmitWithoutValidation(deletePatient, patientId)}
			/>
		</>
	);
};

export default EditPatient;
