import axios from "axios";
import { pick } from "lodash";


export const getAllAcquiredVoucher = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/acquired_vouchers/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getAcquiredVoucher = (acquiredVoucherId) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(acquiredVoucherId)) {
			navigate(`/unknown?entity=acquired_voucher&id=${acquiredVoucherId}`);
			return;
		}

		return axios
			.get(`/api/managers/acquired_vouchers/${acquiredVoucherId}`)
			.then((response) => {
				return response.data.content;
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=acquired_voucher&id=${acquiredVoucherId}`);
			});
	};
};

export const postAcquiredVoucher = (data, activeClinicId, patientId) => {
	const reqData = {
		...pick(data, ["voucher_type_id", "employee_id", "start_date", "due_date", "initial_price", "final_price", "discount", "auto_assign"]),
		remaining_sessions: parseInt(data.remaining_sessions),
		clinic_id: activeClinicId,
		patient_id: patientId,
		...(parseFloat(data.paid) > 0 ? { paid: data.paid } : {}),
		...(parseFloat(data.paid) > 0 ? { payment_method: data.payment_method } : {})
	}

	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/acquired_vouchers/", reqData)
			.then((res) => {
				navigate(
					`/acquired-voucher/${res.data.content.acquired_voucher_id}/?patient=${patientId}`
				);
			})
			.catch(() => { });
	};
};

export const putAcquiredVoucher = (data, acquiredVoucherId, disableEdit) => {
	const reqData = pick(data, ["start_date", "due_date", "auto_assign"])

	return async () => {
		return axios
			.put(`/api/managers/acquired_vouchers/${acquiredVoucherId}`, {
				new_info: reqData
			})
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deleteAcquiredVoucher = (acquiredVoucherId, patientId) => {
	return async (...args) => {
		const { navigate } = args[2];
		return axios
			.delete(`/api/managers/acquired_vouchers/${acquiredVoucherId}`)
			.then(() => {
				navigate(`/patient/${patientId}`);
			})
			.catch(() => { });
	};
};
