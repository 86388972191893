import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomForm from "../../../components/general/form/CustomForm";
import WhatsAppReminder from "../../../components/general/auxiliar/WhatsAppReminder";
import PaginatorComponent from "../../../components/general/auxiliar/PaginatorComponent";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import usePaginationFilterEffects from "../../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllTasks, putTaskStatus } from "../../../actions/task";
import { isDefined } from "../../../auxiliar/formatValidators";

const OverviewTasks = () => {
    const { register, watch, setValue, errors, handleSubmit, handleSubmitWithoutValidation, isSubmitting } = useFormWithDisable();
    const writtenFilter = {
        topic: watch("topic") || "",
        order: watch("order") || "date_asc",
        temporality: watch("temporality") || "all",
        period: watch("period") || "days",
        duration: watch("duration") || "",
        starting_date: watch("starting_date") || "",
        finishing_date: watch("finishing_date") || "",
        display_finished: watch("display_finished") || false,
        exclude_autogenerated: watch("exclude_autogenerated") || false,
    };

    const navigate = useNavigate();
    const { id: clinicId } = useSelector((state) => state.activeClinic);

    const [tasks, setTasks] = useState({ waiting: true });
    const [filter, setFilter] = useState({
        clinic_id: clinicId,
        page: 1,
        per_page: 10,
        order: "date_asc",
        updateCount: 0,
    });

    const update = () => setFilter({ ...filter, updateCount: filter.updateCount + 1 })

    const disabled = usePaginationFilterEffects(filter, writtenFilter, getAllTasks, setTasks, setFilter);

    const formConfig = {
        topic: {
            condition: true,
            disabled: false,
            type: "text",
            label: "Tema",
            placeholder: "Tema",
            name: "topic",
        },
        order: {
            condition: true,
            disabled: false,
            type: "selector",
            label: "Orden",
            options: [
                { key: "date_asc", label: "Fecha - ascendente" },
                { key: "date_desc", label: "Fecha - descendente" },
                { key: "priority_asc", label: "Prioridad - ascendente" },
                { key: "priority_desc", label: "Prioridad - descendente" },
            ],
            defaultValue: "date_asc",
            name: "order",
        },
        temporality: {
            condition: true,
            disabled: false,
            type: "selector",
            name: "temporality",
            label: "Temporalidad",
            options: [
                { key: "all", label: "Todas" },
                { key: "current", label: "Actuales" },
                { key: "future", label: "Posteriores a la fecha actual" },
                { key: "past", label: "Anteriores a la fecha actual" },
                { key: "custom", label: "Filtro personalizado" },
            ],
            defaultValue: "all",
        },
        period: {
            condition: ["future", "past", "current"].includes(writtenFilter.temporality),
            disabled: false,
            type: "selector",
            name: "period",
            label: "Periodo temporal",
            options: [
                { key: "days", label: "Día" },
                { key: "weeks", label: "Semana" },
                { key: "months", label: "Mes" },
                { key: "years", label: "Año" },
            ],
            defaultValue: "days",
        },
        duration: {
            condition: ["future", "past"].includes(writtenFilter.temporality),
            disabled: false,
            type: "number",
            minValue: 1,
            label: "Duración",
            placeholder: "Ilimitado",
            name: "duration",
            info: "Número de días, semanas, meses o años que quieres que dure el filtro. Si no lo rellenas, se tomará como ilimitado y se ignorará el periodo temporal",
        },
        starting_date: {
            condition: writtenFilter.temporality === "custom",
            disabled: false,
            type: "date",
            label: "Fecha incial",
            name: "starting_date",
        },
        finishing_date: {
            condition: writtenFilter.temporality === "custom",
            disabled: false,
            type: "date",
            label: "Fecha final",
            name: "finishing_date",
        },
        dummy: {
            condition: writtenFilter.temporality !== "current",
            type: "dummy",
        },
        display_finished: {
            condition: true,
            disabled: false,
            label: "Finalizadas",
            info: "Si lo seleccionas se mostrarán las tareas aunque estén finalizadas",
            type: "checkbox",
            name: "display_finished",
        },
        exclude_autogenerated: {
            condition: true,
            disabled: false,
            label: "Excluir autogeneradas",
            info: "Si lo seleccionas, se ocultarán las tareas que han sido generadas automáticamente por el sistema",
            type: "checkbox",
            name: "exclude_autogenerated",
        },
    };

    return (
        <div className="sm:container p-3 mx-auto my-5">
            <div className="flex justify-between items-center">
                <h2 className="text-primary text-2xl">Tareas</h2>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmitWithoutValidation(navigate, "/task/register")}
                >
                    Nueva tarea
                </button>
            </div>

            <form className="mt-3 mb-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <CustomForm
                        formConfig={formConfig}
                        errors={errors}
                        register={register}
                    />
                </div>
            </form>

            {tasks.waiting ? (
                <div className="flex items-center justify-center">
                    <span className="loading loading-dots loading-lg" />
                </div>
            ) : !isDefined(tasks.order) ||
                !isDefined(tasks.content) ||
                tasks.order.length <= 0 ? (
                <p className="lg:text-lg my-3">
                    No hay tareas, añade la primera haciendo click en el botón de arriba.
                </p>
            ) : (
                <div className="overflow-x-auto overflow-y-hidden">
                    <p className="text-primary text-xl">
                        {`${tasks.pagination.total_items} ${tasks.pagination.total_items === 1 ? "tarea encontrada" : "tareas encontradas"}`}
                    </p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Tema</th>
                                <th className="hidden sm:table-cell">Paciente</th>
                                <th className="hidden md:table-cell">Teléfono</th>
                                <th className="hidden md:table-cell">Prioridad</th>
                                <th className="hidden md:table-cell">Finalizada</th>
                                <th />
                            </tr>
                        </thead>

                        <tbody>
                            {tasks.order.map((taskId) => {
                                const task = tasks.content && tasks.content[taskId];
                                const finishedId = `finished_${taskId}`;
                                setValue(finishedId, task.finished)
                                return (
                                    <tr key={taskId}>
                                        <td>{task.date}</td>
                                        <td>{task.topic}</td>
                                        <td className="hidden sm:table-cell">
                                            {task.patient_name &&
                                                task.patient_surnames &&
                                                `${task.patient_name} ${task.patient_surnames}`}
                                        </td>
                                        <td className="hidden md:table-cell">
                                            {task.patient_phone_number && (
                                                <div className="flex">
                                                    <span>{task.patient_phone_number}</span>
                                                    <WhatsAppReminder link={task.whatsapp_link} />
                                                </div>
                                            )}
                                        </td>
                                        <td className="hidden md:table-cell">{task.priority}</td>
                                        <td className="hidden md:table-cell">
                                            <div>
                                                <CustomForm
                                                    formConfig={{
                                                        [finishedId]: {
                                                            condition: true,
                                                            disabled: isSubmitting,
                                                            type: "checkbox",
                                                            name: finishedId,
                                                            label: "",
                                                            onChange: handleSubmit(putTaskStatus, taskId, update),
                                                        },
                                                    }}
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </div>
                                        </td>

                                        <td className="text-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={handleSubmitWithoutValidation(navigate, `/task/${taskId}`)}
                                            >
                                                Detalles
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            <form className="mt-5 flex justify-between">
                <PaginatorComponent
                    pagination={tasks.pagination}
                    setFilter={setFilter}
                    filter={filter}
                    disabled={disabled}
                />
            </form>
        </div>
    );
};

export default OverviewTasks;
