
import Collapse from "./Collapse";

const VoucherInfoCollapse = () => {
    return (
        <Collapse
            title="¿Como funcionan los bonos?"
            children={<>
                <p className="mb-4">
                    Los bonos permiten a los pacientes pagar varias sesiones por adelantado. Se configuran aquí, pero deben ser adquiridos desde el perfil de cada paciente. También pueden compartirse entre pacientes.
                </p>

                <p className="mb-4">
                    Si un bono está configurado para asignación automática, se aplicará a todas las sesiones del tipo seleccionado mientras esté vigente en la fecha de la sesión. Sin embargo, incluso si un bono se asigna automáticamente, también podría aplicarse manualmente a otras sesiones si fuera necesario.
                </p>

                <p className="mb-2">
                    Tipos de bonos:
                    <ul className="list-disc list-inside">
                        <li>
                            <strong>Bono de sesiones:</strong> Un conjunto de sesiones utilizables en cualquier momento antes del vencimiento.
                        </li>
                        <li>
                            <strong>Bono temporal:</strong> Un conjunto de sesiones que puede ser ilimitado y utilizables en cualquier momento antes del vencimiento.
                        </li>
                    </ul>
                </p>
            </>}
        />
    )
}

export default VoucherInfoCollapse;