import { useState } from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Collapse = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <details className="collapse" onClick={toggleCollapse}>
            <summary className="collapse-title text-xl text-primary font-medium">
                <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} className="mr-2" />
                {" " + title}
            </summary>
            <div className="collapse-content mx-4 text-md text-justify">
                {children}
            </div>
        </details>
    );
};

export default Collapse;
