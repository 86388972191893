import { isDefined } from "./formatValidators";

export const intRegex = /^\-{0,1}\d+?$/;
export const twoDecimalsRegex = /^\-{0,1}\d+(\.\d{1,2})?$/;

export const priceValidators = {
	required: "El precio es obligatorio",
	pattern: {
		value: twoDecimalsRegex,
		message: "El precio debe ser un número con dos decimales como máximo",
	},
	validate: (v) => {
		if (!twoDecimalsRegex.test(v)) return "El precio debe ser un número con dos decimales como máximo";
		else if (Number(v) < 0) return "El precio debe ser mayor o igual que 0";
	}
};

export const paymentValidators = {
	pattern: {
		value: twoDecimalsRegex,
		message: "El pago debe ser un número con dos decimales como máximo",
	},
	validate: (v) => {
		if (!isDefined(v)) return true;
		else if (!twoDecimalsRegex.test(v)) return "El pago debe ser un número con dos decimales como máximo";
		else if (Number(v) < 0) return "El pago debe ser mayor que 0";
	},
};

export const sessionLengthValidators = {
	required: "La duracion de la sesión es obligatoria",
	pattern: {
		value: intRegex,
		message: "La duración de la sesión debe ser un número sin decimales",
	},
	validate: (v) => {
		if (!intRegex.test(v)) return "La duración de la sesión debe ser un número sin decimales";
		else if (Number(v) < 0) return "La duración de la sesión debe ser mayor que 0";
	}
};

export const sessionsNumberValidators = {
	required: "El número de sesiones es obligatorio",
	pattern: {
		value: intRegex,
		message: "El número de sesiones debe ser un número sin decimales",
	},
	validate: (v) => {
		if (!intRegex.test(v)) return "El número de sesiones debe ser un número sin decimales";
		else if (Number(v) <= 0) return "El número de sesiones debe ser mayor que 0";
	}
};

export const discountValidators = {
	pattern: {
		value: intRegex,
		message: "El descuento debe ser un número sin decimales",
	},
	validate: (v) => {
		if (!isDefined(v)) return true;
		else if (!intRegex.test(v)) return "El descuento debe ser un número sin decimales";
		else if (Number(v) < 0) return "El descuento debe ser mayor o igual que 0";
		else if (Number(v) > 100) return "El descuento debe ser menor o igual que 100";
	},
};

export const birthDateValidators = {
	validate: (v) => {
		if (!isDefined(v)) return
		if (new Date(v).getTime() > new Date().getTime()) {
			return "La fecha de nacimiento no puede ser posterior a la fecha actual"
		}
	},
};
