import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faEuro, faCircleInfo, faTicket } from "@fortawesome/free-solid-svg-icons";
import { faCreativeCommonsNcEu } from "@fortawesome/free-brands-svg-icons";

import CustomForm from "../../../components/general/form/CustomForm";
import PayWithCashRegister from "../../../components/payment/PayWithCashRegister";
import PayWithAcquiredVoucher from "../../../components/payment/PayWithAcquiredVoucher";
import DisplayInvoice from "../../../components/cashRegister/DisplayInvoice";
import OverviewCharges from "../../../components/charge/OverviewCharges";
import EditSessionPopup from "../../../components/services/session/popupEditSession/EditSessionPopup";
import OptionSelector from "../../../components/general/auxiliar/OptionSelector";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import { deleteCashRegister, getCashRegister } from "../../../actions/cashRegister";
import { postCharge } from "../../../actions/charge";
import { postInvoice, downloadInvoice, sendInvoiceEmail } from "../../../actions/invoice";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";


const CashRegisterCard = ({ }) => {
	const openTooltipEdit = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		setValue,
		watch,
		errors,
		isSubmitting,
	} = useFormWithDisable()

	const { cash_register_id: cashRegisterId } = useParams();
	const { id: clinicId } = useSelector((state) => state.activeClinic);

	const [cashRegister, setCashRegister] = useState({});
	const [boolRefresh, setBoolRefresh] = useState(false);
	const [display, setDisplay] = useState({
		main: "cashRegister",
		charges: "chargesHistory",
	});

	// Form configuration
	const formConfig = {
		patient_name_tmp: {
			condition: true,
			disabled: true,
			defaultValue: cashRegister?.patient_name,
			type: "text",
			label: "Nombre",
			name: "patient_name_tmp",
			redirect: `/patient/${cashRegister?.patient_id}`,
		},
		patient_surnames_tmp: {
			condition: true,
			disabled: true,
			defaultValue: cashRegister?.patient_surnames,
			type: "text",
			label: "Apellidos",
			name: "patient_surnames_tmp",
		},
		patient_email_tmp: {
			condition: true,
			disabled: true,
			defaultValue: cashRegister?.patient_email,
			type: "text",
			label: "Correo Electrónico",
			name: "patient_email_tmp",
			mailto: isDefined(cashRegister?.patient_email) ? `mailto:${cashRegister?.patient_email}` : undefined,
		},
		patient_phone_number_tmp: {
			condition: true,
			disabled: true,
			defaultValue: cashRegister?.patient_phone_number,
			whatsAppLink: cashRegister?.whatsAppLink,
			type: "text",
			label: "Número de teléfono",
			name: "patient_phone_number_tmp",
		},
		employee_id_tmp: {
			condition: true,
			disabled: true,
			defaultValue: cashRegister?.employee_id,
			type: "physio_selector",
			label: "Fisioterapeuta *",
			name: "employee_id_tmp",
		},
		// Only one of the following three fields will be displayed
		voucher_type_id_tmp: {
			condition: isDefined(cashRegister?.voucher_type_id),
			disabled: true,
			defaultValue: cashRegister?.voucher_type_id,
			type: "acquired_voucher_selector",
			label: "Tipo de bono",
			name: "voucher_type_id_tmp",
		},
		session_type_id_tmp: {
			condition: isDefined(cashRegister?.session_type_id),
			disabled: true,
			defaultValue: cashRegister?.session_type_id,
			type: "session_type_selector",
			label: "Tipo de sesión",
			name: "session_type_id_tmp",
		},
		group_session_type_id_tmp: {
			condition: isDefined(cashRegister?.group_session_type_id),
			disabled: true,
			defaultValue: cashRegister?.group_session_type_id,
			type: "group_session_type_selector",
			label: "Tipo de sesión",
			name: "session_type_id_tmp",
		},
		entity_date_tmp: {
			condition: isDefined(cashRegister?.entity_date),
			disabled: true,
			defaultValue: cashRegister?.entity_date,
			type: "date",
			label: `Fecha de ${isDefined(cashRegister?.session_type_id) && "sesión" ||
				isDefined(cashRegister?.group_session_type_id) && "clase" ||
				isDefined(cashRegister?.voucher_type_id) && "adquisición"}`,
			name: "entity_date_tmp",
			openPopup: (isDefined(cashRegister?.session_id) || isDefined(cashRegister?.group_session_id)) ?
				handleSubmitWithoutValidation(openTooltipEdit.current, {
					id: isDefined(cashRegister?.session_id) ? cashRegister.session_id : cashRegister.group_session_id,
					event_type: isDefined(cashRegister?.session_id) ? "individual" : "group",
				}) :
				undefined,
			redirect: isDefined(cashRegister?.acquired_voucher_id) ?
				`/acquired-voucher/${cashRegister?.acquired_voucher_id}` :
				undefined,
		},
		issue_date_tmp: {
			condition: true,
			disabled: true,
			defaultValue: cashRegister?.issue_date,
			type: "date",
			label: "Fecha de emisión",
			name: "issue_date_tmp",
		},
		final_price_tmp: {
			condition: true,
			disabled: true,
			defaultValue: cashRegister?.final_price,
			type: "number",
			label: "Precio final",
			name: "final_price_tmp",
			info: "Precio después del descuento",
		},
		debt_tmp: {
			condition: true,
			disabled: true,
			defaultValue: cashRegister?.debt,
			type: "number",
			label: "Deuda",
			name: "debt_tmp",
		},
	};

	// Selector options
	const mainOptions = {
		cashRegister: {
			condition: true,
			title: "Albarán",
			icon: faHistory,
			selected: display.main === "cashRegister",
			onClick: () => setDisplay({ ...display, main: "cashRegister" }),
		},
		invoice: {
			condition: isDefined(cashRegister?.invoice_id),
			disabled: false,
			title: "Factura",
			icon: faEuro,
			selected: display.main === "invoice",
			onClick: () => setDisplay({ ...display, main: "invoice" }),
		},
	};

	// Selector options
	const chargesOptions = {
		chargesHistory: {
			condition: true,
			title: "Historial de pagos",
			icon: faHistory,
			selected: display.charges === "chargesHistory",
			onClick: () => setDisplay({ ...display, charges: "chargesHistory" }),
		},
		newCharge: {
			condition: Number(cashRegister?.debt) > 0 || Number(cashRegister.final_price) === 0,
			disabled: false,
			title: Number(cashRegister.final_price) === 0 ? "Editar albarán" : "Nuevo pago",
			icon: faEuro,
			selected: display.charges === "newCharge",
			onClick: () => setDisplay({ ...display, charges: "newCharge" }),
		},
		newPayback: {
			condition: Number(cashRegister?.final_price) - Number(cashRegister?.debt) > 0,
			disabled: false,
			title: "Nueva devolución",
			icon: faCreativeCommonsNcEu,
			selected: display.charges === "newPayback",
			onClick: () => setDisplay({ ...display, charges: "newPayback" }),
		},
		assignVoucher: {
			condition: cashRegister?.is_voucher_assignable,
			disabled: false,
			title: "Asignar bono",
			icon: faTicket,
			selected: display.charges === "assignVoucher",
			onClick: () => setDisplay({ ...display, charges: "assignVoucher" }),
		},
	};


	useEffect(() => {
		fetchData(dispatch, getCashRegister, cashRegisterId, setCashRegister);
	}, [cashRegisterId, boolRefresh]);

	const refresh = (display) => {
		setBoolRefresh(!boolRefresh);
		if (display) setDisplay(display);
	}

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<div>
				<div className="flex items-center justify-between">
					<h2 className="text-primary text-2xl">{mainOptions[display.main]?.title}</h2>

					{/* Displayed selector */}
					{isDefined(cashRegister?.invoice_id) && <OptionSelector options={mainOptions} />}
				</div>

				{display.main === "cashRegister" && (
					<form className={"my-3 grid grid-cols-1 md:grid-cols-2 gap-3"}>
						<CustomForm
							formConfig={formConfig}
							errors={errors}
							register={register}
						/>
					</form>
				)}

				{display.main === "invoice" && <DisplayInvoice invoiceId={cashRegister?.invoice_id} />}


				<div className="mt-5 flex space-x-2 sm:space-x-4">
					{isDefined(cashRegister?.invoice_id) ? (
						<>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(
									downloadInvoice,
									cashRegister?.invoice_id
								)}
							>
								Descargar Factura
							</button>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(
									sendInvoiceEmail,
									cashRegister?.invoice_id
								)}
							>
								Enviar Factura{" "}
								<div
									className="tooltip flex items-center"
									data-tip={"La factura se enviará al correo asociado al paciente"}
								>
									<FontAwesomeIcon icon={faCircleInfo} />
								</div>
							</button>
						</>
					) : (
						<>
							<button
								className="btn btn-primary btn-sm"
								disabled={isSubmitting}
								onClick={handleSubmitWithoutValidation(
									postInvoice,
									clinicId,
									cashRegisterId,
									() => refresh({ ...display, main: "invoice" })
								)}
							>
								Crear Factura
							</button>
						</>
					)}
					{cashRegister.is_deletable && <button
						className="btn btn-error btn-sm"
						onClick={handleSubmitWithoutValidation(deleteCashRegister, undefined, cashRegisterId)}
					>
						Eliminar
					</button>}
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmitWithoutValidation(navigate, -1)}
					>
						Atrás
					</button>
				</div>
			</div>

			<div className="my-8">
				<div className="flex items-center justify-between">
					<h2 className="text-primary text-xl">{chargesOptions[display.charges]?.title}</h2>

					{/* Displayed selector */}
					<OptionSelector options={chargesOptions} />
				</div>

				{display.charges === "chargesHistory" && <OverviewCharges
					cashRegisterId={cashRegisterId}
					boolRefresh={boolRefresh}
					refresh={refresh}
				/>}

				{["newCharge", "newPayback"].includes(display.charges) && (<>
					<div className="my-3">
						<PayWithCashRegister
							version={display.charges === "newPayback" ? "return" : "pay"}
							register={register}
							errors={errors}
							setValue={setValue}
							watch={watch}
							originalValues={cashRegister}
						/>
					</div>

					<div className="mt-5 flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(
								postCharge,
								clinicId,
								cashRegister?.cash_register_id,
								() => refresh({ ...display, charges: "chargesHistory" })
							)}
							disabled={isSubmitting}
						>
							Confirmar
						</button>
					</div>
				</>)}

				{display.charges === "assignVoucher" && (<>
					<div className="my-3">
						<PayWithAcquiredVoucher
							register={register}
							errors={errors}
							setValue={setValue}
							watch={watch}
							originalValues={cashRegister}
							entityDate={cashRegister?.entity_date}
						/>
					</div>

					<div className="mt-5 flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(
								deleteCashRegister,
								cashRegister?.cash_register_id,
							)}
							disabled={isSubmitting}
						>
							Confirmar
						</button>
					</div>
				</>)}
			</div>

			<EditSessionPopup childFunc={openTooltipEdit} refresh={refresh} />
		</div>
	);
};

export default CashRegisterCard;
