import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../../../components/general/form/CustomForm";
import {
	checkMailFormat,
	checkPhoneNumberFormat,
	checkDNIFormat,
	isDefined,
} from "../../../auxiliar/formatValidators";
import { postPatient } from "../../../actions/patient";
import { discountValidators, birthDateValidators } from "../../../auxiliar/useFormValidators";

const RegisterPatient = () => {
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		watch,
		errors,
		isSubmitting,
	} = useFormWithDisable();

	const navigate = useNavigate();
	const comment = watch("comments");
	const { id: activeClinicId } = useSelector((state) => state.activeClinic);

	const formConfig = {
		name: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Nombre *",
			placeholder: "Nombre",
			name: "name",
			validators: { required: "El nombre es obligatorio" },
		},
		surnames: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Apellidos *",
			placeholder: "Apellidos",
			name: "surnames",
			validators: { required: "Los apellidos son obligatorios" },
		},
		email: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Correo Electrónico",
			placeholder: "paciente@email.com",
			name: "email",
			validators: {
				validate: (v) => !isDefined(v) || checkMailFormat(v),
			},
		},
		phone_number: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Número de teléfono",
			placeholder: "+34 XXX XXX XXX",
			name: "phone_number",
			validators: {
				validate: (v) => !isDefined(v) || checkPhoneNumberFormat(v),
			},
		},
		dni: {
			condition: true,
			disabled: false,
			type: "text",
			label: "DNI",
			name: "dni",
			placeholder: "12345678A",
			validators: { validate: (v) => !isDefined(v) || checkDNIFormat(v) },
		},
		address: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Direccion",
			placeholder: "Calle 123, Ciudad",
			name: "address",
		},
		birth_date: {
			condition: true,
			disabled: false,
			type: "date",
			label: "Fecha de nacimiento",
			name: "birth_date",
			validators: birthDateValidators
		},
		discount_percentage: {
			condition: true,
			disabled: false,
			type: "number",
			label: "Porcentaje de descuento habitual",
			placeholder: "Ejemplo: 10",
			name: "discount_percentage",
			info: "Podrás modificar el descuento en cada cita. Si no se introduce un valor, se guardará como 0.",
			validators: discountValidators,
			minValue: 0,
			maxValue: 100
		},
		comments: {
			condition: true,
			disabled: false,
			type: "textarea",
			label: `Anamnesis (${comment?.length || 0}/10000)`,
			placeholder: "...",
			name: "comments",
			validators: {
				maxLength: {
					value: 10000,
					message: "El comentario es demasiado largo",
				},
			},
		},
		reminders: {
			condition: true,
			disabled: false,
			info: "Si activas los recordatorios podrás enviarle mensajes para recordarle las citas desde la aplicación.",
			label: "Activar recordatorios",
			type: "checkbox",
			name: "reminders",
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Registro de Paciente</h2>
			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(postPatient, activeClinicId)}
						disabled={isSubmitting}
					>
						Confirmar
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmitWithoutValidation(
							navigate,
							"/patient"
						)}
						disabled={isSubmitting}
					>
						Cancelar
					</button>
				</div>
			</form>
		</div>
	);
};
export default RegisterPatient;
