import axios from "axios";
import { pick } from "lodash";
import { isDefined } from "../auxiliar/formatValidators";

export const postTask = (data) => {
	if (data.add_patient) {
		if (data.patient_id === "new") {
			data.new_patient_info = {
				name: data.patient_name,
				surnames: data.patient_surnames,
				email: data.patient_email,
				phone_number: data.patient_phone_number,
			};
		}
	} else {
		delete data.patient_id
	}
	delete data.add_patient;
	delete data.patient_name;
	delete data.patient_surnames;
	delete data.patient_email;
	delete data.patient_phone_number;

	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/tasks/", data)
			.then(() => {
				navigate("/task");
			})
			.catch(() => { });
	};
};

export const getAllTasks = (params) => {
	const reqParams = pick(params, ["clinic_id", "page", "per_page", "order", "topic", "temporality", "display_finished", "exclude_autogenerated"])
	let temporalParams = {}
	if (reqParams.temporality === "current") {
		temporalParams = pick(params, ["period"])
	} else if (["future", "past"].includes(reqParams.temporality)) {
		temporalParams = pick(params, ["period", "duration"])
	} else if (reqParams.temporality === "custom") {
		temporalParams = pick(params, ["starting_date", "finishing_date"])
	}

	Object.keys(temporalParams).forEach(key => !isDefined(temporalParams[key]) && delete temporalParams[key])

	return async () => {
		return axios
			.get(`/api/managers/tasks/`, { params: { ...reqParams, ...temporalParams } })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getTask = (taskId) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(taskId)) {
			navigate(`/unknown?entity=task&id=${taskId}`);
			return;
		}

		return axios
			.get(
				`/api/managers/tasks/${taskId}`
			)
			.then((response) => {
				return response.data.content;
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=task&id=${taskId}`);
			});
	};
};

export const putTask = (data, taskId, disableEdit) => {
	const reqData = pick(data, ["color", "date", "description", "priority", "topic", "finished"])
	return async () => {
		return axios
			.put(`/api/managers/tasks/${taskId}`, { new_info: reqData })
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const putTaskStatus = (data, taskId, update) => {
	const value = data[`finished_${taskId}`]

	return async () => {
		return axios
			.put(`/api/managers/tasks/${taskId}`, { new_info: { finished: value } })
			.then(() => {
				setTimeout(() => update(), 200);
			})
			.catch(() => { });
	};
}


export const deleteTask = (taskId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.delete(`/api/managers/tasks/${taskId}`)
			.then(() => {
				navigate("/task");
			})
			.catch(() => { });
	};
};
