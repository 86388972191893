import { useSelector } from "react-redux";

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../../../components/general/form/CustomForm";
import { postClinic } from "../../../actions/clinics";
import {
	checkCIFFormat,
	checkMailFormat,
	checkPhoneNumberFormat,
} from "../../../auxiliar/formatValidators";

const RegisterClinic = () => {
	const { register, handleSubmit, watch, errors, isSubmitting, setValue } =
		useFormWithDisable();

	const managerId = useSelector((state) => state.auth.id);
	const invoiceCode = watch("invoice_code");
	const invoiceNumber = watch("invoice_number");
	const addSchedule = watch("add_schedule");

	const invoiceInfo =
		invoiceCode && invoiceNumber
			? `La primera factura generada será ${invoiceCode}-${invoiceNumber}`
			: `La primera factura generada será el resultado de concatenar el código de factura y el número de factura`;

	const formConfig = {
		name: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Nombre *",
			placeholder: "Nombre",
			name: "name",
			validators: { required: "El nombre es obligatorio" },
		},
		email: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Correo Electrónico *",
			placeholder: "clinica@email.com",
			name: "email",
			validators: {
				required: "El correo es obligatorio",
				validate: checkMailFormat,
			},
		},
		landline_phone: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Número de teléfono fijo",
			placeholder: "XXX XXX XXX",
			name: "landline_phone",
			validators: {
				validate: checkPhoneNumberFormat,
			},
		},
		mobile_phone: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Número de teléfono móvil",
			placeholder: "+34 XXX XXX XXX",
			name: "mobile_phone",
			validators: {
				validate: checkPhoneNumberFormat,
			},
		},
		address: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Dirección *",
			placeholder: "Calle, número",
			name: "address",
		},
		town: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Ciudad",
			placeholder: "Ciudad",
			name: "town",
		},
		invoice_code: {
			condition: true,
			disabled: false,
			info: invoiceInfo,
			type: "text",
			label: "Código de factura *",
			placeholder: "EX",
			name: "invoice_code",
			validators: {
				required: "El código de factura es obligatorio",
				minLength: {
					value: 2,
					message: "El código de factura debe tener entre 2 y 5 caracteres",
				},
				maxLength: {
					value: 5,
					message: "El código de factura debe tener entre 2 y 5 caracteres",
				},
			},
		},
		invoice_number: {
			condition: true,
			disabled: false,
			info: invoiceInfo,
			type: "number",
			label: "Número de factura inical *",
			placeholder: "Ejemplo: 0",
			name: "invoice_number",
			minValue: 0,
			validators: {
				required: "El número de factura inicial es obligatorio",
				min: {
					value: 0,
					message: "El número de factura inicial no puede ser negativo",
				},
			},
		},
		cif: {
			condition: true,
			disabled: false,
			info: "El identificador fiscal es necasario para la generación de facturas",
			type: "text",
			label: "CIF *",
			placeholder: "00000000X",
			name: "cif",
			validators: { validate: checkCIFFormat },
		},
		dummy: {
			condition: true,
			type: "dummy",
		},
		online_booking: {
			condition: true,
			disabled: false,
			info: "Si activas las reservas online, los pacientes podrán reservar citas a través de la web",
			label: "Activar reservas online",
			type: "checkbox",
			name: "online_booking",
		},
		task_generation: {
			condition: true,
			disabled: false,
			info: "Si activas la creación de recordatorios se crearán tareas en los cumpleaños de los pacientes",
			label: "Activar creación de recordatorios",
			type: "checkbox",
			name: "task_generation",
		},
		add_schedule: {
			condition: true,
			disabled: false,
			label: "Añadir horario de apertura",
			info: "El horario aparecerá en la agenda automáticamente",
			type: "checkbox",
			name: "add_schedule",
		},
		dummy_2: {
			condition: true,
			type: "dummy",
		},
		schedule: {
			condition: addSchedule,
			disabled: false,
			type: "schedule",
			className: "mt-5",
			watch: watch,
			setValue: setValue,
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Registro de Clínica</h2>
			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(postClinic, managerId)}
						disabled={isSubmitting}
					>
						Confirmar
					</button>
				</div>
			</form>
		</div>
	);
};

export default RegisterClinic;
